.error-validation {
    color: red;
}

.min-width {
    width: 8%;
}

.required-icon {
    color: red;
}


hr.auto {
    width: 90%;
    margin: 10px auto;
    height: 0.1px;
    background-color: #F7F7F7;
    
}

.line-title {
    margin-top: 30px;
    border-bottom: 1px solid #CCC;
    
}

